import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import IDo from "../components/iDo/iDo"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"

const IDoDetail = ({
  data: { iDo, seo },
  pageContext: { parent, title, language }
}) => {
  return (
    <ProtectedRoute>
      <Layout parent={parent} title={title}>
        <HelmetDatoCms
          defer={false}
          seo={iDo.seoMetaTags}
          language={language}
        />
        <IDo {...iDo} />
      </Layout>
    </ProtectedRoute>
  )
}
export const query = graphql`
  query iDoById($id: String) {
    iDo: datoCmsIDo(id: { eq: $id }) {
      ...IDo
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default IDoDetail

import React from "react"
import { graphql } from "gatsby"
import style from "./iDo.mod.scss"
import * as FirebaseComments from "../Comments"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../ProtectedRoute"
import Spinner from "../global/layout/Spinner"
import Vimeo from "@elements/Vimeo"
import Block from "../global/layout/Block"
import Content from "@elements/Content"

const IDoDetailPage = ({
  id,
  originalId,
  title,
  description,
  descriptionNode,
  meta,
  url,
  subHeading,
  language,
  audioFile,
  showComments = false,
  chosenThumbnail
}) => {
  const { loading, user } = useAuth0()

  if (loading || !user) {
    return <Spinner />
  }

  const getIDoImage = () => {
    return chosenThumbnail
  }

  return (
    <ProtectedRoute>
      <div className={style["i-do"]}>
        <div className={style["i-do__hero"]}>
          <Vimeo
            videoUrl={url}
            audioFile={audioFile && audioFile.url}
            poster={getIDoImage()}
          />
        </div>
        <Block padding="top" gutters={true} maxWidth="inner">
          <div className={style["i-do__content"]}>
            <div className={style["i-do__top_row"]}>
              <p className={style["i-do__category"]}>{"DediKate I Do"}</p>
            </div>
            <div className={style["i-do__bottom_row"]}>
              <h1 className={style["i-do__heading"]}>{title}</h1>
            </div>
            <p className={style["i-do__date"]}>{subHeading}</p>
            {description && (
              <Content html={descriptionNode.childMarkdownRemark.html} />
            )}
          </div>
        </Block>
        {showComments && (
          <IDoComments>
            <FirebaseComments.List id={originalId} />
            <FirebaseComments.SubmitHeader
              text="Submit a Comment"
              loggedAs={user?.name}
            />
            <FirebaseComments.SubmitForm id={id} originalId={originalId} />
          </IDoComments>
        )}
      </div>
    </ProtectedRoute>
  )
}

export default IDoDetailPage

export const IDoComments = ({ children }) => {
  return <>{children}</>
}

export const query = graphql`
  fragment IDo on DatoCmsIDo {
    id
    originalId
    slug
    title
    subHeading
    description
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    audioFile {
      url
    }
    showComments
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    url {
      url
      providerUid
    }
    chosenThumbnail {
      gatsbyImageData(imgixParams: { w: "1280", h: "720", fit: "crop" })
    }
    meta {
      publishedAt
    }
  }
`
